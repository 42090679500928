/* Global Styles */
@font-face {
  font-family: "Gotham Black";
  src: url("/GothamBlack.otf") format("opentype");
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Gotham Black";
  background-image: url("/sitebackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
}

/* Custom Fonts */
h1,
h2,
h3,
.gta-font {
  font-family: "Gotham Black";
}

/* Color Variables */
:root {
  --gta-blue: #3498db;
  --gta-purple: #9b59b6;
  --background-color: #000;
  --foreground-color: #fff;
}

/* Gradient Background */
.bg-gradient {
  background: linear-gradient(-45deg, #1a237e, #4a148c, #1a237e, #4a148c);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

/* Glowing Effect */
.glow {
  text-shadow: 0 0 5px #1a237e, 0 0 10px #1a237e, 0 0 15px #1a237e,
    0 0 20px #1a237e;
}

/* Border Radius */
.lg {
  border-radius: 10px;
}
.md {
  border-radius: 8px;
}
.sm {
  border-radius: 6px;
}
